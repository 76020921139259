import Vue from "vue";
import { GridPlugin } from "@syncfusion/ej2-vue-grids";
import TitleMixin from "@/mixins/title";
import SpinnerMixin from "@/mixins/spinner";
import { getStatisticTypeFormat } from '@/helpers/statisticType';
Vue.use(GridPlugin);
export default Vue.extend({
    mixins: [TitleMixin, SpinnerMixin],
    data() {
        return {
            nextId: 0,
            title: this.$route.params.id == undefined ? this.$t("intervalManagement.create.title") : this.$t("intervalManagement.edit.title"),
            isEdit: false,
            rangesTypes: [],
            data: {
                id: null,
                type: null,
                typeName: null,
                values: [],
            },
            validationOptions: {
                rules: {
                    rangesTypes: {
                        required: true,
                        number: true
                    },
                }
            }
        };
    },
    computed: {
        typeFormat() {
            return getStatisticTypeFormat(this.data.type);
        }
    },
    watch: {
        typeFormat(newValue, oldValue) {
            this.changeValuesFormat(newValue, oldValue);
        }
    },
    async mounted() {
        this.showSpinner();
        try {
            this.isEdit = this.$route.params.id != undefined;
            this.rangesTypes = await this.$store.dispatch("statistics/rangesTypes", {
                load: true
            });
            if (this.isEdit) {
                this.data = await this.$store.dispatch(`statistics/valueRangesById`, {
                    id: this.$route.params.id
                });
                this.data.values.sort((first, second) => first.initialValue - second.initialValue);
                if (this.typeFormat === "P2") {
                    this.data.values.forEach(value => {
                        if (value.initialValue != null)
                            value.initialValue = value.initialValue * 100;
                        if (value.finalValue != null)
                            value.finalValue = value.finalValue * 100;
                    });
                }
            }
            else {
                this.addInterval(null);
            }
            this.hideSpinner();
        }
        catch (errors) {
            this.hideSpinner();
            this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("shared.toastFailureContent", {
                error: errors[0].message
            }));
        }
    },
    methods: {
        changeValuesFormat(newFormat, oldFormat) {
            if (newFormat === "P2" && oldFormat !== "P2") {
                const values = [...this.data.values];
                values.forEach(value => {
                    if (value.initialValue != null)
                        value.initialValue = value.initialValue / 100;
                    if (value.finalValue != null)
                        value.finalValue = value.finalValue / 100;
                });
                this.data.values = [];
                setTimeout(() => {
                    this.data.values = values;
                }, 0);
            }
            if (newFormat !== "P2" && oldFormat === "P2") {
                const values = [...this.data.values];
                values.forEach(value => {
                    if (value.initialValue != null)
                        value.initialValue = value.initialValue * 100;
                    if (value.finalValue != null)
                        value.finalValue = value.finalValue * 100;
                });
                this.data.values = [];
                setTimeout(() => {
                    this.data.values = values;
                }, 0);
            }
        },
        async create() {
            return await this.$store.dispatch(`statistics/newValueRanges`, this.data);
        },
        createSuccess() {
            this.$toast.showSuccessToast(this.$t("intervalManagement.create.toastSuccessTitle"), this.$t("intervalManagement.create.toastSuccessContent"));
            this.backToGridPage();
        },
        update() {
            return this.$store.dispatch(`statistics/editValueRanges`, this.data);
        },
        updateSuccess() {
            this.$toast.showSuccessToast(this.$t("intervalManagement.edit.toastSuccessTitle"), this.$t("intervalManagement.edit.toastSuccessContent"));
            this.backToGridPage();
        },
        cancel() {
            this.backToGridPage();
        },
        backToGridPage() {
            return this.$router.push('/statistics/value-ranges');
        },
        addInterval(min) {
            const colorIndex = this.data.values.length;
            const color = colorIndex == 0 ? "#FF0000" :
                colorIndex == 1 ? "#FFFF00" :
                    colorIndex == 2 ? "#00FF00" :
                        "#00FFFF";
            this.nextId++;
            this.data.values.push({
                id: this.nextId,
                initialValue: min,
                finalValue: null,
                color: color
            });
            const form = this.$refs["interval-management-form"];
            form.addRules(`initialValue_${this.nextId}`, {
                required: true,
                number: true
            });
            form.addRules(`finalValue_${this.nextId}`, {
                required: true,
                number: true
            });
        },
        removeInterval(id) {
            const form = this.$refs["interval-management-form"];
            form.removeRules(`initialValue_${id}`);
            form.removeRules(`finalValue_${id}`);
            this.data.values = this.data.values.filter(interval => interval.id != id);
        }
    }
});
